<script setup lang="ts">
import { upperFirst } from 'scule'
import type { CurrencyCode, OrderDetailLineItemFragment } from '#graphql-operations'

const props = withDefaults(
  defineProps<{
    line: OrderDetailLineItemFragment
    currency: CurrencyCode
    editable?: boolean
  }>(),
  {
    editable: true,
  },
)

const emit = defineEmits<{
  (event: 'remove:orderLine', orderLine?: OrderDetailLineItemFragment): void
  (event: 'change:orderLine', orderLine?: OrderDetailLineItemFragment, quantity?: number): void
  (event: 'click:link', orderLine?: OrderDetailLineItemFragment): void
}>()

const { t } = useI18n()
const { removeOrderLine, adjustOrderLine } = useActiveOrder()
const priceFormatter = usePrice()

async function onRemoveOrderLine(line: OrderDetailLineItemFragment) {
  await removeOrderLine(line)
  emit('remove:orderLine', line)
}

async function onAdjustOrderLine(line: OrderDetailLineItemFragment, quantity: number) {
  await adjustOrderLine(line, quantity)
  emit('change:orderLine', line, quantity)
}

const { editable, line } = toRefs(props)
const getMaxQuantity = (quantity = 1) => quantity > 10 ? quantity : 10
</script>

<template>
  <li class="flex py-1">
    <div class="relative h-24 w-24 flex-shrink-0 overflow-hidden border border-gray-200 rounded-md">
      <NuxtImg
        v-if="line.featuredAsset?.preview"
        :src="line.featuredAsset.preview"
        :alt="line.productVariant.product.name"
        class="h-full w-full object-cover object-center"
        loading="lazy"
      />
      <ContentGuard>
        <span class="absolute bottom-0 left-0 right-0 bg-green-200/60 py1 text-center text-center text-xs font-bold uppercase">
          {{ t('trustable.in_stock') }}
        </span>
      </ContentGuard>
    </div>

    <div class="ml-2 flex flex-1 flex-col">
      <div>
        <div class="font-sm flex justify-between text-base text-gray-900">
          <h3>
            <NLink
              class="line-clamp-2 overflow-hidden text-sm no-underline"
              :to="`/products/${line.productVariant.product.id}/${line.productVariant.product.slug}`"
              @click="$emit('click:link', line)"
            >
              {{ line.productVariant.product.name }}
            </NLink>
          </h3>
          <div class="flex shrink grow flex-col flex-gap2">
            <p class="ml-4 font-bold">
              {{ priceFormatter(line.linePrice, props.currency) }}
            </p>
          </div>
        </div>
        <div v-if="line.productVariant.options.length" class="flex text-sm text-gray-500">
          <template v-for="(option, index) in line.productVariant.options" :key="option.code">
            <p class="flex-shrink-0">
              {{ upperFirst(option.name) }}
            </p>
            <p v-if="index < line.productVariant.options.length - 1" class="mx-2 flex-shrink-0">
              |
            </p>
          </template>
        </div>

        <div v-if="!editable" class="text-gray-500">
          Qty {{ line.quantity }}
        </div>
      </div>
      <div class="flex flex-1 items-end justify-between text-sm">
        <FormSelect
          v-if="editable"
          :id="`quantity-${line.id}`"
          :disabled="!editable"
          :name="`quantity-${line.id}`"
          :model-value="line.quantity"
          :data-items="Array.from({ length: getMaxQuantity(line.quantity) }, (_, i) => i + 1)"
          @change="onAdjustOrderLine(line, +$event.target.value)"
        />
        <div class="flex">
          <a
            v-if="editable"
            n="sm orange6 dark:orange4 hover:orange5"
            href=""
            class="text-gray-400 font-medium hover:text-gray-700"
            @click.prevent="onRemoveOrderLine(line)"
          >
            <NIcon icon="ph:x" />&nbsp;{{ t('general.remove.label') }}
          </a>
        </div>
      </div>
    </div>
  </li>
</template>
