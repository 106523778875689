<script setup lang="ts">
const route = useRoute()
const isCheckout = computed(() => typeof route.name === 'string' && route.name.includes('checkout'))
</script>

<template>
  <div
    class="z-40 w-full flex-none bg-orange-8 backdrop-blur transition-colors duration-500 lg:z-50 lg:border-b dark:border-slate-50/[0.06] lg:border-slate-900/10 dark:bg-transparent"
  >
    <div class="mx-auto max-w-[88rem] w-full px-2 lg:px-8 sm:px-4 md:pt-2 lg:divide-y lg:divide-slate-200">
      <div class="relative mx0.5 h-16 flex justify-between border-b border-slate-900/10 py-4 lg:mx0 lg:border-0 lg:px8 sm:mx-4 dark:border-slate-300/10">
        <!-- Logo -->
        <div class="flex flex-1 items-center px0 lg:px-0 sm:px-2">
          <div class="flex shrink-0 items-center">
            <div class="flex flex-1 justify-start">
              <slot name="logo" />
            </div>
          </div>
        </div>

        <!-- Search bar -->
        <div class="hidden max-w-lg w-full md:block">
          <slot name="center" />
        </div>

        <!-- Navigation items -->
        <div class="flex flex-1 items-center justify-end">
          <slot name="right" />
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!isCheckout"
    class="z-40 w-full flex-none bg-[#fbfaf7] backdrop-blur drop-shadow-sm transition-colors duration-500 lg:z-50 lg:border-b dark:border-slate-50/[0.06] lg:border-slate-900/10 dark:bg-transparent"
  >
    <div class="mx-auto max-w-[88rem] w-full px-2 lg:px-8 sm:px-4 md:pt-2 lg:divide-y lg:divide-slate-200">
      <!-- TAG BOTTOM BAR -->
      <div class="hidden h-14 overflow-hidden lg:flex dark:border-slate-50/[0.06]">
        <slot name="navbar" />
        <slot name="trust-element" />
      </div>
    </div>

    <!-- <div class="w-full flex px-4 py-3 shadow sm:hidden">
      <slot name="search" />
    </div> -->
  </div>
  <div v-else class="z-40 w-full flex-none backdrop-blur drop-shadow-sm transition-colors duration-500 lg:z-50 lg:border-b dark:border-slate-50/[0.06] lg:border-slate-900/10 dark:bg-transparent">
    <div class="mx-auto max-w-[88rem] w-full px-2 lg:px-8 sm:px-4 lg:divide-y lg:divide-slate-200">
      <!-- TAG BOTTOM BAR -->
      <div class="md overflow-hidden lg:flex dark:border-slate-50/[0.06]">
        <div class="mx0 w-full flex flex-col items-center justify-center md:mx4 lg:flex md:flex-row md:justify-between lg:px-6 lg:py-3 lg:space-x-6">
          <div class="flex items-center py3 md:py0">
            <NIcon icon="ph:shield-check-fill" class="text-5xl color-green-600" />
            <div class="ml2 flex flex-col">
              <h2 class="text-xl font-bold color-green-600">
                ZABEZPEČENÁ POKLADNA
              </h2>
            </div>
          </div>
          <div class="flex items-center justify-center gap-x-2 py4 sm:gap-x-6 md:py0">
            <div class="flex flex-col items-center">
              <NIcon icon="ph:check-circle-duotone" class="text-center text-2xl color-green-600" />
              <div class="ml0 flex flex-col sm:ml2">
                <h2 class="text-center text-xs font-bold color-green-600 sm:text-sm">
                  Záruka vrácení<br>peněz
                </h2>
              </div>
            </div>
            <div class="flex flex-col items-center">
              <NIcon icon="ph:trophy-duotone" class="text-center text-2xl color-green-600" />
              <div class="ml0 flex flex-col sm:ml2">
                <h2 class="text-center text-xs font-bold color-green-600 sm:text-sm">
                  Účast v soutěži o<br>&nbsp;5&nbsp;000&nbsp;000&nbsp;Kč
                </h2>
              </div>
            </div>
            <div class="flex flex-col items-center justify-center">
              <NIcon icon="ph:package-duotone" class="text-center text-2xl color-green-600" />
              <div class="ml0 flex flex-col sm:ml2">
                <h2 class="text-center text-xs font-bold color-green-600 sm:text-sm">
                  Expresní odeslání<br>do&nbsp;24&nbsp;hodin
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
